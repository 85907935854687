<template>
  <Card class="h-full purchase-container">
    <div class="flex items-center justify-between py-4">
      <h5 class="text-lg font-semibold">Payments</h5>
    </div>
    <div class="flex items-center justify-end flex-wrap">
      <Card class="searchContainer w-44 mr-2" p="px-3">
        <Select type="filter" placeholder="Status" v-model="params.statuses" :options="paymentStatus" :reduce="(type) => type.value" />
      </Card>
      <SearchInput placeholder="Customer Id" v-model="params.customerId" class="py-2 mr-2" />
      <SearchInput placeholder="Loan Id" v-model="params.loanId" class="py-2 mr-2" />
      <!-- overdue date filer -->
      <DatePicker class="mr-2" v-model="range" is-range :model-config="modelConfig" :masks="masks">
        <template v-slot="{ inputValue, togglePopover }">
          <Card class="flex items-center border searchContainer w-full" p="px-1.5 py-1">
            <button class="p-2 focus:outline-none" @click="togglePopover()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                <path
                  d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                  fill="#9D9D9D"
                  stroke="#9D9D9D"
                  stroke-width=".1"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                  fill="#9D9D9D"
                  stroke="#9D9D9D"
                  stroke-width=".1"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <p v-if="inputValue.start === null" @click="togglePopover()" class="text-grey">Overdue Date filter</p>
            <p v-else @click="togglePopover()" class="text-sm text-grey">{{ inputValue.start }} - {{ inputValue.end }}</p>
          </Card>
        </template>
      </DatePicker>
      <!-- due date picker -->
      <DatePicker class="mr-2" v-model="dueDateRange" is-range :model-config="modelConfig" :masks="masks">
        <template v-slot="{ inputValue, togglePopover }">
          <Card class="flex items-center border searchContainer w-full" p="px-1.5 py-1">
            <button class="p-2 focus:outline-none" @click="togglePopover()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                <path
                  d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                  fill="#9D9D9D"
                  stroke="#9D9D9D"
                  stroke-width=".1"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                  fill="#9D9D9D"
                  stroke="#9D9D9D"
                  stroke-width=".1"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <p v-if="inputValue.start === null" @click="togglePopover()" class="text-grey">Due Date filter</p>
            <p v-else @click="togglePopover()" class="text-sm text-grey">{{ inputValue.start }} - {{ inputValue.end }}</p>
          </Card>
        </template>
      </DatePicker>
      <!-- completed at date picker -->
      <DatePicker v-model="completedDateRange" is-range :model-config="modelConfig" :masks="masks">
        <template v-slot="{ inputValue, togglePopover }">
          <Card class="flex items-center border searchContainer w-full" p="px-1.5 py-1">
            <button class="p-2 focus:outline-none" @click="togglePopover()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                <path
                  d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                  fill="#9D9D9D"
                  stroke="#9D9D9D"
                  stroke-width=".1"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                  fill="#9D9D9D"
                  stroke="#9D9D9D"
                  stroke-width=".1"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <p v-if="inputValue.start === null" @click="togglePopover()" class="text-grey">Completed Date filter</p>
            <p v-else @click="togglePopover()" class="text-sm text-grey">{{ inputValue.start }} - {{ inputValue.end }}</p>
          </Card>
        </template>
      </DatePicker>
    </div>
    <div class="flex items-center justify-end mt-4">
      <Button text="Search Filter" @click="handleSearch" class="mr-2" p="p-2" />
      <Button outline text="Clear filter" @click="clearSearch" p="p-2" />
    </div>
    <Hr />
    <Spinner class="text-center" v-if="purchaseLoading" />
    <div v-if="!purchaseLoading">
      <div>
        <div class="payment-table">
          <div class="payment-table__col font-semibold">S/N</div>
          <div class="payment-table__col font-semibold">id</div>
          <div class="payment-table__col font-semibold">Total Amount</div>
          <div class="payment-table__col font-semibold">Amount Recovered</div>
          <div class="payment-table__col font-semibold">Amount Owed</div>
          <div class="payment-table__col font-semibold">Accrued interest</div>
          <div class="payment-table__col font-semibold">Card Failure Count</div>
          <div class="payment-table__col font-semibold">Due date</div>
          <div class="payment-table__col font-semibold">Status</div>
          <div class="payment-table__col font-semibold">Action</div>
          <!-- <div class="payment-table__col font-semibold">Date</div>
          <div class="payment-table__col font-semibold">Status</div>
          <div class="payment-table__col font-semibold">Action</div> -->
        </div>
        <Hr />
        <EmptyState description="No records found" v-if="allSchedules.length === 0" />
        <div v-else>
          <div v-for="(purchase, index) in allSchedules" :key="purchase.id">
            <div class="payment-table">
              <div class="payment-table__col">{{ index + 1 }}</div>
              <div class="payment-table__col">{{ purchase.id }}</div>
              <div class="payment-table__col">
                {{ purchase.totalAmount | formatMoney }}
              </div>
              <div class="payment-table__col">
                {{ purchase.amountRecovered | formatMoney }}
              </div>
              <div class="payment-table__col">
                {{ purchase.amountOwed | formatMoney }}
              </div>
              <div class="payment-table__col">
                {{ purchase.accruedOverdueInterest | formatMoney }}
              </div>
              <div class="payment-table__col">
                {{ purchase.cardDebitFailureCount }}
              </div>
              <div class="payment-table__col">
                {{ dayjs(purchase.dueDate).format("MMM D YYYY | hh:mmA") }}
              </div>
              <div class="payment-table__col" :class="[purchase.status.toLowerCase()]">
                {{ purchase.status.toLowerCase() }}
              </div>
              <div class="payment-table__col">
                <SecondaryButton text="View details" fontSize="text-xs" class="w-40" @click="$router.push(`/customers/${purchase.customerId}`)" />
              </div>
            </div>
            <Hr />
          </div>
          <Pagination :handleMore="handleMore" :loading="paginateLoading" @paginate="handlePagination" />
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import Spinner from "@/UI/Spinner";
  import Hr from "@/UI/Hr";
  import { Select, Input, SearchInput } from "@/UI/Input";
  import { Button, SecondaryButton } from "@/UI/Button";
  import { DatePicker } from "v-calendar";
  import dayjs from "dayjs";
  import { Modal } from "@/UI/Modals";

  export default {
    components: {
      Spinner,
      Hr,
      Select,
      Input,
      SearchInput,
      Button,
      DatePicker,
      SecondaryButton,
      Modal,
      EmptyState: () => import("@/UI/EmptyState"),
      Pagination: () => import("@/UI/Pagination"),
    },
    data: () => ({
      params: {
        page: 0,
        pageSize: 20,
        statuses: "",
        becameOverdueAtFrom: "",
        becameOverdueAtTo: "",
        dueDateFrom: "",
        dueDateTo: "",
        completedAtFrom: "",
        completedAtTo: "",
        customerId: "",
        loanId: "",
      },
      paymentStatus: [
        { label: "All", value: "" },
        { label: "Pending", value: "PENDING" },
        { label: "Overdue", value: "OVERDUE" },
        { label: "Success", value: "SUCCESS" },
      ],
      modelConfig: {
        type: "string",
        mask: "iso",
        start: {
          timeAdjust: "00:00:00",
        },
        end: {
          timeAdjust: "23:59:59",
        },
      },
      range: {
        start: "",
        end: "",
      },
      dueDateRange: {
        start: "",
        end: "",
      },
      completedDateRange: {
        start: "",
        end: "",
      },
      masks: {
        input: "DD MMM YY",
      },
      paginateLoading: false,
      purchaseLoading: false,
      handleMore: false,
    }),
    computed: {
      ...mapState({
        allSchedules: (state) => state.purchases.allSchedules,
      }),
      dayjs() {
        return dayjs;
      },
    },
    mounted() {
      this.getSchedules();
    },
    methods: {
      ...mapActions("purchases", ["getAllRecoverySchedules"]),
      getSchedules(type) {
        type === "paginate" ? (this.paginateLoading = true) : (this.purchaseLoading = true);
        this.getAllRecoverySchedules({ params: this.$route.query.data ? JSON.parse(this.$route.query.data) : this.params, type: type })
          .then((res) => {
            if (res.data.data.length === this.params.pageSize) {
              this.handleMore = true;
            } else {
              this.handleMore = false;
            }
            type === "paginate" ? (this.paginateLoading = false) : (this.purchaseLoading = false);
          })
          .catch(() => {
            type === "paginate" ? (this.paginateLoading = false) : (this.purchaseLoading = false);
          });
      },
      handlePagination() {
        this.paginateLoading = true;
        this.params.page++;
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            data: JSON.stringify(this.params),
          },
        });
        this.getSchedules("paginate");
      },

      handleSearch() {
        this.params.page = 0;
        this.params.becameOverdueAtFrom = this.range.start;
        this.params.becameOverdueAtTo = this.range.end;
        this.params.dueDateFrom = this.dueDateRange.start;
        this.params.dueDateTo = this.dueDateRange.end;
        this.params.completedAtFrom = this.completedDateRange.start;
        this.params.completedAtFrom = this.completedDateRange.end;
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            data: JSON.stringify(this.params),
          },
        });
        this.getSchedules();
      },
      clearSearch() {
        this.params.page = 0;
        this.params.becameOverdueAtFrom = "";
        this.params.becameOverdueAtTo = "";
        this.params.dueDateFrom = "";
        this.params.dueDateTo = "";
        this.params.completedAtFrom = "";
        this.params.completedAtFrom = "";
        this.params.loanId = "";
        this.params.customerId = "";
        this.params.statuses = "";
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            data: JSON.stringify(this.params),
          },
        });
        this.getSchedules();
      },
    },
  };
</script>
<style scoped>
  .searchContainer {
    border: 1px solid rgba(138, 116, 192, 0.1) !important;
    min-width: 8rem;
  }
  .purchase-container {
    min-height: calc(100vh - 10rem);
  }
  .payment-table {
    display: grid;
    grid-template-columns: 2.5rem 12rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    align-items: center;
  }
  .payment-table__col {
    padding: 0.8rem 0.5rem;
    /* white-space: nowrap; */
    font-size: 0.9rem;
  }
</style>
